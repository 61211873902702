<template>
    <el-form ref="form" :model="form" label-width="80px">
        <el-form-item label="表单名称">
            <el-input v-model="form.name"></el-input>
        </el-form-item>
        <el-form-item label="选择器">
            <el-select v-model="form.region" placeholder="请选择活动区域">
                <el-option label="步步高" value="shanghai"></el-option>
                <el-option label="小天才" value="beijing"></el-option>
                <el-option label="imoo" value="zhengzhou"></el-option>
            </el-select>
        </el-form-item>
        <el-form-item label="活动时间">
            <el-col :span="11">
                <el-date-picker type="date" placeholder="选择日期" v-model="form.date1" style="width: 100%;"></el-date-picker>
            </el-col>
            <el-col class="line" :span="2">-</el-col>
            <el-col :span="11">
                <el-time-picker placeholder="选择时间" v-model="form.date2" style="width: 100%;"></el-time-picker>
            </el-col>
        </el-form-item>
        <el-form-item label="选择开关">
            <el-switch v-model="form.delivery"></el-switch>
        </el-form-item>
        <el-form-item label="城市级联">
            <el-cascader :options="options">
                <template slot-scope="{ node, data }">
                    <span>{{ data.label }}</span>
                    <span v-if="!node.isLeaf"> ({{ data.children.length }}) </span>
                </template>
            </el-cascader>
        </el-form-item>
        <el-form-item label="多选框">
            <el-checkbox-group v-model="form.type">
                <el-checkbox label="步步高" name="type"></el-checkbox>
                <el-checkbox label="小天才" name="type"></el-checkbox>
                <el-checkbox label="imoo" name="type"></el-checkbox>
            </el-checkbox-group>
        </el-form-item>
        <el-form-item label="单选框">
            <el-radio-group v-model="form.resource">
                <el-radio label="步步高"></el-radio>
                <el-radio label="小天才"></el-radio>
                <el-radio label="imoo"></el-radio>
            </el-radio-group>
        </el-form-item>
        <el-form-item label="文本框">
            <el-input type="textarea" v-model="form.desc"></el-input>
        </el-form-item>
        <el-form-item>
            <el-button type="primary" @click="onSubmit">立即创建</el-button>
            <el-button>取消</el-button>
        </el-form-item>
    </el-form>
</template>

<script>
export default {
  data () {
    return {
      form: {
        name: '',
        region: '',
        date1: '',
        date2: '',
        delivery: false,
        type: [],
        resource: '',
        desc: ''
      },
      options: [{
        value: 'hsj',
        label: '河南',
        children: [{
          value: 'hsj',
          label: '郑州',
          children: [{
            value: 'hsj',
            label: '二七区'
          }]
        }]
      }]
    }
  },
  methods: {
    onSubmit () {
      console.log('submit!')
    }
  }
}
</script>

<style scoped>

</style>
